"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
require("./base");
require("webpack-jquery-ui/css");
require("jquery-validation");
require("jquery-validation/dist/additional-methods.js");
require("../stylesheets/style.less");
require("../stylesheets/cookieconsent.css");
require("bootstrap/dist/js/bootstrap.bundle.js");
require("webpack-jquery-ui/tooltip");
var config_1 = require("./config");
var $ = require("jquery");
var form_1 = require("./form");
$.getJSON("./assets/wattwin-config.json").then(function (data) {
    var form = new form_1.Form(config_1.config.backendPoint, config_1.config.context);
});
