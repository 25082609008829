"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messages_cat = void 0;
exports.messages_cat = {
    Address: "La direcció és obligatòria",
    Province: "La província és obligatòria",
    PostalCode: {
        required: "El codi postal és obligatori",
        pattern: "El codi postal no té el format correcte",
    },
    Profiles: {
        required: "El tipus d'empresa és obligatori",
    },
    Number: "El número és obligatori",
    City: "La localitat és obligatòria",
    Tariff: "La tarifa és obligatòria",
    ContractedPower: {
        required: "La potència contractada és obligatòria",
        pattern: "La potència no té el format correcte",
    },
    Name: "El nom és obligatori",
    LastName: "El cognom és obligatori",
    Email: {
        required: "L'email és obligatori",
        pattern: "Si us plau, escriu una adreça de correu vàlida. Per exemple perez@dominio.com ",
        email: "Si us plau, escriu una adreça de correu vàlida. Per exemple perez@dominio.com ",
    },
    referralEmployee: {
        required: "El nom del gestor és obligatori",
    },
    referralEmail: {
        required: "L'email del gestor és obligatori",
        pattern: "Els dominis admesos són endesa.com, enel.com, endesa.es i enel.es",
        email: "Si us plau, escriu una adreça de correu vàlida.",
    },
    Terms: "Les condicions han de ser acceptades",
    documentNumber: {
        required: "El document és obligatori",
        pattern: "El format del document no és correcte",
        nifES: "Si us plau, introdueix un NIF vàlid",
        dniES: "Si us plau, introdueix un DNI vàlid",
        nieES: "Si us plau, introdueix un NIE vàlid",
    },
    PhoneNumber: {
        required: "El telèfon és obligatori",
        minlength: "El telèfon ha de tenir 9 números",
        number: "El telèfon ha de composar-se de números",
        phoneNumberEs: "El format del telèfon no és correcte",
    },
    CUPS: {
        required: "El CUPS és obligatori",
        pattern: "El format del CUPS no és correcte",
    },
    ContactName: {
        required: "El nom és obligatori",
    },
    ContactLastName: {
        required: "El cognom és obligatori",
    },
    ContactDocumentNumber: {
        required: "El document és obligatori",
        pattern: "El format del document no és correcte",
        dniES: "Si us plau, introdueix un DNI vàlid",
        nieES: "Si us plau, introdueix un NIE vàlid",
    },
    TotalConsumption: {
        required: "El consum és obligatori",
        pattern: "El format del consum ha de ser numèric",
    },
};
