"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setValidationMessages = void 0;
var i18next = require("i18next");
var i18nextXHRBackend = require("i18next-xhr-backend");
var jqueryI18next = require("jquery-i18next");
var config_1 = require("./config");
var $ = require("jquery");
i18next.use(i18nextXHRBackend).init({
    fallbackLng: "es",
    debug: false,
    backend: {
        loadPath: 
        //'../' +
        //window.location.pathname
        //.split('/')
        //.slice(2, -1)
        //.join('/') +
        "./assets/i18n/{{lng}}.json",
    },
}, function (err, t) {
    jqueryI18next.init(i18next, $);
    $(function () {
        updateContent();
        // Check if urlParams contain lng to translate
        var href = window.location.href.split("#");
        var urlParams = {};
        href[0].replace(/[?&]+([^=&]+)=([^&]*)/gi, function (a, name, value) {
            urlParams[name] = value;
            return "";
        });
        if (urlParams.lng) {
            var lang = $('.dropdown a[data-lng="' + urlParams.lng + '"]');
            if (lang.length > 0) {
                updateMenu(lang);
                changeLng(urlParams.lng);
            }
        }
    });
});
function updateContent() {
    $("#step1").localize();
    $("#step2").localize();
    $("#step3").localize();
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="tooltip"]').each(function () {
        var title = $(this).attr("title");
        if (title.length != 0) {
            $(this).attr("data-original-title", title);
        }
    });
    // Terms
    $("#modal-terms").load("./assets/i18n/conditions_" + getLanguage() + ".html");
    // Payment
    $("#modal-payment").load("./assets/i18n/payment_" + getLanguage() + ".html");
    // Cups
    $("#modal-cups").load("./assets/i18n/cups_" + getLanguage() + ".html");
    // Errors
    $("#modal-error").load("./assets/i18n/error_" + getLanguage() + ".html");
    //Error when area is small
    $("#modal-areaSmall-error").load("./assets/i18n/areaSmall_error_" + getLanguage() + ".html");
    $("#modalPowerPlant-error").load("./assets/i18n/powerPlant-error_" + getLanguage() + ".html");
}
function changeLng(lng) {
    i18next.changeLanguage(lng);
    setValidationMessages();
}
i18next.on("languageChanged", function () {
    updateContent();
});
$(function () {
    $(document.body).on("click", ".dropdown a", function () {
        updateMenu(this);
        changeLng(this.dataset.lng);
    });
});
function updateMenu(current) {
    var menu = $(".dropdown_container");
    var first = $("<li></li>").append($(".dropdown > a"));
    $(".dropdown").empty().append(current).append(menu);
    $(".dropdown_container").append(first);
}
function getLanguage() {
    var lang = i18next.default.options.fallbackLng[0];
    if (i18next.default.language != undefined) {
        lang = i18next.default.language;
    }
    return lang;
}
function setValidationMessages() {
    var lang = getLanguage();
    $("#step3Form").validate(lang).settings.messages = {
        Name: config_1.config.formMessages[lang].Name,
        LastName: config_1.config.formMessages[lang].LastName,
        Email: {
            required: config_1.config.formMessages[lang].Email.required,
            pattern: config_1.config.formMessages[lang].Email.pattern,
            email: config_1.config.formMessages[lang].Email.email,
            refEmail: config_1.config.formMessages[lang].Email.refEmail,
        },
        Terms: config_1.config.formMessages[lang].Terms,
        documentNumber: {
            required: config_1.config.formMessages[lang].documentNumber.required,
            nifES: config_1.config.formMessages[lang].documentNumber.nifES,
            nieES: config_1.config.formMessages[lang].documentNumber.nieES,
            dniES: config_1.config.formMessages[lang].documentNumber.dniES,
        },
        documentType: config_1.config.formMessages[lang].documentType,
        PhoneNumber: {
            required: config_1.config.formMessages[lang].PhoneNumber.required,
            minlength: config_1.config.formMessages[lang].PhoneNumber.minlength,
            number: config_1.config.formMessages[lang].PhoneNumber.number,
            phoneNumberEs: config_1.config.formMessages[lang].PhoneNumber.phoneNumberEs,
        },
        CUPS: {
            required: config_1.config.formMessages[lang].CUPS.required,
            pattern: config_1.config.formMessages[lang].CUPS.pattern,
        },
        ContactName: config_1.config.formMessages[lang].ContactName,
        ContactLastName: config_1.config.formMessages[lang].ContactLastName,
        ContactDocumentNumber: {
            required: config_1.config.formMessages[lang].ContactDocumentNumber.required,
            pattern: config_1.config.formMessages[lang].ContactDocumentNumber.pattern,
            nifES: config_1.config.formMessages[lang].ContactDocumentNumber.nifES,
            nieES: config_1.config.formMessages[lang].ContactDocumentNumber.nieES,
        },
        referralEmployee: {
            required: config_1.config.formMessages[lang].referralEmployee.required,
        },
        referralEmail: {
            required: config_1.config.formMessages[lang].referralEmail.required,
            pattern: config_1.config.formMessages[lang].referralEmail.pattern,
            email: config_1.config.formMessages[lang].referralEmail.email,
        },
        Profiles: {
            required: config_1.config.formMessages[lang].Profiles.required,
        },
    };
}
exports.setValidationMessages = setValidationMessages;
