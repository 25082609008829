"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MapLayout = void 0;
require("../assets/js/maplabel.js");
var _ = require("lodash");
var MapLayout = /** @class */ (function () {
    function MapLayout(product, config) {
        this.product = product;
        this.config = config;
        this.product = product;
        this.config = config;
    }
    /**
     * Generate the a serie of module polygons into the google map
     * @param refresh Flag to generate all the modules or update the existing ones
     * @param area Module area
     * @param polygon Area polygon
     * @param modules List of module polygons
     * @param areaPolygons array of area polygons
     * @param moduleArea array of moduleAreas
     * @param module_options Draw options
     */
    MapLayout.prototype.drawModules = function (refresh, area, polygon, modules, areaPolygons, moduleArea, module_options) {
        var bounds = new google.maps.LatLngBounds();
        var paths = polygon.getPath();
        // Calculate the rectangle in which all the points of the polygon will enter
        paths.forEach(function (latlng) {
            bounds.extend(latlng);
        });
        var map = polygon.getMap();
        var projection = map.getProjection();
        /** Coordinates of the center of the area */
        var center = new google.maps.LatLng((bounds.getNorthEast().lat() + bounds.getSouthWest().lat()) / 2, (bounds.getSouthWest().lng() + bounds.getNorthEast().lng()) / 2);
        var origin = projection.fromLatLngToPoint(center);
        /** Each corner of the area/rectangle */
        var nwBounds = new google.maps.LatLng(bounds.getNorthEast().lat() +
            Math.abs(bounds.getNorthEast().lng() - center.lng()) / 2, bounds.getSouthWest().lng() -
            Math.abs(bounds.getSouthWest().lat() - center.lat()) / 2);
        var neBounds = new google.maps.LatLng(bounds.getNorthEast().lat() +
            Math.abs(bounds.getNorthEast().lng() - center.lng()) / 2, bounds.getNorthEast().lng() +
            Math.abs(bounds.getNorthEast().lat() - center.lat()) / 2);
        var seBounds = new google.maps.LatLng(bounds.getSouthWest().lat() -
            Math.abs(bounds.getSouthWest().lng() - center.lng()) / 2, bounds.getNorthEast().lng() +
            Math.abs(bounds.getNorthEast().lat() - center.lat()) / 2);
        var swBounds = new google.maps.LatLng(bounds.getSouthWest().lat() -
            Math.abs(bounds.getSouthWest().lng() - center.lng()) / 2, bounds.getSouthWest().lng() -
            Math.abs(bounds.getSouthWest().lat() - center.lat()) / 2);
        var horizontalDistance = google.maps.geometry.spherical.computeDistanceBetween(nwBounds, neBounds);
        var verticalDistance = google.maps.geometry.spherical.computeDistanceBetween(nwBounds, swBounds);
        /** Calculate the total angle */
        area.tilt = area.modulesTilt + area.surfaceTilt * (area.south ? 1 : -1);
        var inclinationAngle = area.tilt * (Math.PI / 180);
        /** Calculate the total orientation */
        var orientationAngle;
        if (area.tilt !== 0)
            orientationAngle =
                (-area.azimuth + area.surfaceAzimuth) * (Math.PI / 180);
        else
            orientationAngle = 0;
        var productHeight;
        var productWidth;
        var angleProductHeight = area.moduleMatrixDisposition !== "eastWest"
            ? Math.cos(inclinationAngle)
            : Math.cos(orientationAngle);
        var angleProductWidth = area.moduleMatrixDisposition !== "eastWest"
            ? Math.cos(orientationAngle)
            : Math.cos(area.modulesTilt * (area.south ? 1 : -1) * (Math.PI / 180));
        if (area.vertical) {
            productHeight =
                ((this.product.properties.length * area.moduleMatrixVertical) / 1000) *
                    angleProductHeight;
            productWidth =
                ((this.product.properties.width * area.moduleMatrixHorizontal) / 1000) *
                    angleProductWidth;
        }
        else {
            productWidth =
                ((this.product.properties.length * area.moduleMatrixHorizontal) /
                    1000) *
                    angleProductWidth;
            productHeight =
                ((this.product.properties.width * area.moduleMatrixVertical) / 1000) *
                    angleProductHeight;
        }
        /** Calculate the number of modules that fit in that rectangle from the product properties*/
        var maxHorizontalBoxes = horizontalDistance / productWidth;
        var maxVerticalBoxes = verticalDistance / productHeight;
        /** Calculate the separation between modules based on the input data*/
        var horizontalSeparation = horizontalDistance / (productWidth + area.rowDistance);
        var verticalSeparation = verticalDistance / (productHeight + area.columnDistance);
        var ystep = Math.abs(nwBounds.lat() - swBounds.lat()) / verticalSeparation;
        var boxH = Math.abs(nwBounds.lat() - swBounds.lat()) / maxVerticalBoxes;
        var xstep = Math.abs(nwBounds.lng() - neBounds.lng()) / horizontalSeparation;
        var boxW = Math.abs(nwBounds.lng() - neBounds.lng()) / maxHorizontalBoxes;
        var modulesPositions = [];
        var increment = 1;
        var id = area.id + increment;
        var smallArea = true;
        this.activeModules = 0;
        for (var y = 0; y < Math.ceil(maxVerticalBoxes); y++) {
            for (var x = 0; x < Math.ceil(maxHorizontalBoxes); x++) {
                var moduleSw = new google.maps.LatLng(swBounds.lat() + ystep * y, swBounds.lng() + xstep * x);
                var moduleSe = new google.maps.LatLng(swBounds.lat() + ystep * y, swBounds.lng() + xstep * x + boxW);
                var moduleNe = new google.maps.LatLng(swBounds.lat() + ystep * y + boxH, swBounds.lng() + xstep * x + boxW);
                var moduleNw = new google.maps.LatLng(swBounds.lat() + ystep * y + boxH, swBounds.lng() + xstep * x);
                var points = [];
                points.push(projection.fromPointToLatLng(this.rotatePoint(projection.fromLatLngToPoint(moduleNw), origin, -area.azimuth)));
                points.push(projection.fromPointToLatLng(this.rotatePoint(projection.fromLatLngToPoint(moduleNe), origin, -area.azimuth)));
                points.push(projection.fromPointToLatLng(this.rotatePoint(projection.fromLatLngToPoint(moduleSe), origin, -area.azimuth)));
                points.push(projection.fromPointToLatLng(this.rotatePoint(projection.fromLatLngToPoint(moduleSw), origin, -area.azimuth)));
                // Array to tell if each point is inside or not
                var isInside = [];
                var newPositions = [];
                for (var i = 0; i < points.length; i++) {
                    // Check if the point is inside the area
                    isInside.push(google.maps.geometry.poly.containsLocation(points[i], polygon));
                    newPositions.push(points[i]);
                }
                modulesPositions.push(newPositions);
                var flagCount = _.countBy(isInside);
                if (flagCount.true === 4)
                    smallArea = false;
                if (refresh) {
                    if (flagCount.true > 0)
                        modules.push(this.generateModule(newPositions, area, id, map, flagCount.true === 4 &&
                            this.activeModules < this.maxActiveModules, module_options));
                }
                else {
                    var moduleObj = _.find(modules, function (obj) {
                        return obj.id === id;
                    });
                    // If exists, set the new coordinates
                    if (moduleObj)
                        moduleObj.setPath(newPositions);
                }
                increment++;
                id = area.id + increment;
            }
        }
        if (smallArea)
            this.setActiveModulesOnSmallArea(area, modules, module_options);
        var maxModules = this.getNumberActiveModulesByArea(modules, area);
        var totalModules = this.getNumberActiveModules(modules, moduleArea);
        var totalPower = this.getActiveModulesTotalPower(modules, moduleArea);
        var totalSurface = this.getSurfaces(areaPolygons);
        return { maxModules: maxModules, totalModules: totalModules, totalPower: totalPower, totalSurface: totalSurface };
    };
    /**
     * Sets the max active modules
     * @param {number} totalModules Total number of modules to draw
     */
    MapLayout.prototype.setMaxActiveModules = function (totalModules) {
        this.maxActiveModules = totalModules;
    };
    /**
     * If area is small set active true to the minimum of modules
     * @param area Area of the module polygon
     * @param modules List of module polygons
     * @param module_options Draw options
     */
    MapLayout.prototype.setActiveModulesOnSmallArea = function (area, modules, module_options) {
        var activeModules = Math.round(area.surface / area.moduleSurface);
        if (activeModules > this.maxActiveModules)
            activeModules = this.maxActiveModules;
        var modulesObj = _.filter(modules, function (obj) { return obj.areaId === area.id; });
        for (var _i = 0, _a = modulesObj.slice(0, activeModules); _i < _a.length; _i++) {
            var module_1 = _a[_i];
            module_1.strokeWeight = 1;
            module_1.active = true;
            module_1.fillOpacity = 0.5;
            module_1.visible = this.config.showModules;
            if (module_options) {
                if (module_options.showModules) {
                    module_1.fillOpacity = module_options.fillOpacity;
                    module_1.strokeWeight = module_options.strokeWeight;
                }
                else {
                    module_1.fillOpacity = 0;
                    module_1.strokeWeight = 0;
                }
            }
        }
    };
    /**
     * Generate the module polygon attributes
     * @param position Coordinates of the module polygon
     * @param area Area of the module polygon
     * @param id id of the module
     * @param map Google map instance
     * @param active Flag to show the module activated or not
     * @param module_options Draw options
     * @returns The polygon object
     */
    MapLayout.prototype.generateModule = function (position, area, id, map, active, module_options) {
        if (active)
            this.activeModules++;
        var boxObject = {
            paths: position,
            map: map,
            strokeColor: module_options ? module_options.strokeColor : "#00ffff",
            strokeOpacity: module_options ? module_options.strokeOpacity : 0.8,
            strokeWeight: active ? 1 : 0,
            fillColor: module_options ? module_options.fillColor : "#0000ff",
            fillOpacity: active ? 0.5 : 0,
            active: active,
            clickable: module_options ? module_options.clickable : true,
            draggable: module_options ? module_options.draggable : true,
            zIndex: 100,
            areaId: area.id,
            id: id,
            visible: this.config.showModules,
        };
        if (module_options) {
            if (module_options.showModules) {
                boxObject.fillOpacity = active ? module_options.fillOpacity : 0;
                boxObject.strokeWeight = active ? module_options.strokeWeight : 0;
            }
            else {
                boxObject.fillOpacity = 0;
                boxObject.strokeWeight = 0;
            }
        }
        return new google.maps.Polygon(boxObject);
    };
    /**
     * Rotate a point within the map from an angle and a center
     * @param point coordinates to rotate
     * @param origin coordinates of the area center
     * @param angle area azimuth
     * @returns the rotated point
     */
    MapLayout.prototype.rotatePoint = function (point, origin, angle) {
        var angleRad = (angle * Math.PI) / 180.0;
        return new google.maps.Point(Math.cos(angleRad) * (point.x - origin.x) -
            Math.sin(angleRad) * (point.y - origin.y) +
            origin.x, Math.sin(angleRad) * (point.x - origin.x) +
            Math.cos(angleRad) * (point.y - origin.y) +
            origin.y);
    };
    /**
     * Returns the number of active modules in one area
     * @param layoutModules powerplant layout
     * @param area powerplant area
     * @returns number of active modules or 0
     */
    MapLayout.prototype.getNumberActiveModulesByArea = function (layoutModules, area) {
        var numberModulesByArea = _.countBy(layoutModules, function (obj) {
            return obj.areaId === area.id && obj.active === true;
        });
        return (numberModulesByArea.true *
            area.moduleMatrixHorizontal *
            area.moduleMatrixVertical || 0);
    };
    /**
     * Returns the number of all active modules
     * @param layoutModules powerplant layout
     * @param moduleArea array of powerplant areas
     * @returns number of active modules or 0
     */
    MapLayout.prototype.getNumberActiveModules = function (layoutModules, moduleArea) {
        var total = 0;
        var _loop_1 = function (area) {
            var numberModulesByArea = _.countBy(layoutModules, function (obj) {
                return obj.areaId === area.id && obj.active === true;
            });
            if (numberModulesByArea.true)
                total +=
                    numberModulesByArea.true *
                        area.moduleMatrixHorizontal *
                        area.moduleMatrixVertical;
        };
        for (var _i = 0, moduleArea_1 = moduleArea; _i < moduleArea_1.length; _i++) {
            var area = moduleArea_1[_i];
            _loop_1(area);
        }
        return total || 0;
    };
    /**
     * Returns power of all active modules
     * @param moduleArea: layout.moduleArea
     * @param layoutModules powerplant layout
     * @returns power of all active modules
     */
    MapLayout.prototype.getActiveModulesTotalPower = function (layoutModules, moduleArea) {
        var power = 0;
        for (var _i = 0, moduleArea_2 = moduleArea; _i < moduleArea_2.length; _i++) {
            var area = moduleArea_2[_i];
            var modules = this.getNumberActiveModulesByArea(layoutModules, area);
            if (modules > 0)
                power += area.modulePower * modules;
        }
        return power;
    };
    /**
     * Get area surface in m2
     * @param polygons: all polygons
     * @param areaId
     */
    MapLayout.prototype.getSurface = function (polygons, areaId) {
        var polygon = _.find(polygons, function (obj) {
            return obj.id === areaId;
        });
        var m2 = 0;
        if (polygon !== undefined) {
            m2 = google.maps.geometry.spherical.computeArea(polygon.getPath());
        }
        return m2;
    };
    /**
     * Get total area surface in m2
     * @param polygons all polygons
     */
    MapLayout.prototype.getSurfaces = function (polygons) {
        var total = 0;
        for (var _i = 0, polygons_1 = polygons; _i < polygons_1.length; _i++) {
            var polygon = polygons_1[_i];
            if (polygon !== undefined) {
                total += this.getSurface(polygons, polygon.id);
            }
        }
        return total;
    };
    return MapLayout;
}());
exports.MapLayout = MapLayout;
