"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Form = void 0;
var config_1 = require("./config");
var translate_1 = require("./translate");
var form_map_1 = require("./form.map");
var $ = require("jquery");
var _ = require("lodash");
var i18next = require("i18next");
var Form = /** @class */ (function () {
    function Form(endPoint, defaultVariables) {
        this.urlParams = {};
        this.offer = {};
        this.defaultParams = {};
        this.isReferralToggle = true;
        this.validAddress = false;
        this.individualProfile;
        this.elevation = 0;
        this.polygons = [];
        this.surfaceCoordinates = [[]];
        this.searchWithoutAddress = false;
        this.waitingResponse = false;
        this.areaM2 = [];
        this.curStep = 1;
        this.referral = false;
        this.nAreas = 0;
        this.submitted = false;
        this.endPoint = endPoint;
        this.referral = false;
        this.validTariffs = [
            "2.0TD",
            "3.0TD",
            "3.0TDVE",
            "6.1TD",
            "6.2TD",
            "6.3TD",
            "6.4TD",
            "6.1TDVE",
        ];
        this.tariffOptions = {};
        this.getTariffs(config_1.config.context.defaultCountryCode);
        this.consumptionType;
        this.calculatedConsumption;
        this.tariffName;
        this.contractedPower;
        this.legalRepresentation = "INDIVIDUAL";
        this.checkIFrame();
        this.checkLimitations();
        this.joinDefaultVariables(defaultVariables);
        this.getUrlVariables();
        this.initOffer();
        this.mapa = new form_map_1.Mapa(this.moduleId);
        this.initAutocomplete();
        this.goToStep(1);
        // this.validateStep1Form();
        this.validateStep3Form();
        translate_1.setValidationMessages();
        this.setEvents();
        this.getCups();
        // });
    }
    /**
     * Checks some config show conditions in order to display or not the content
     */
    Form.prototype.checkLimitations = function () {
        if (!config_1.config.showLanguageSelector)
            $(".language-selector").css("display", "none");
    };
    /**
     * Check if the form is is inside an iframe or not
     */
    Form.prototype.checkIFrame = function () {
        this.isIFrame = window.self !== window.top;
        if (this.isIFrame) {
            $("#header").css("display", "none");
        }
    };
    /**
     * Calculate the total consumption
     * @param consumptionType 'power' or 'cost'
     * @param consumption total consumption in kWh (power) or € (cost)
     * @param tariffName tariff name selected
     * @param contractedPower contracted power in kW
     */
    Form.prototype.calculateConsumption = function (consumptionType, consumption, tariffName, contractedPower) {
        var totalConsumptionCalculated;
        var tariff = _.find(this.tariffOptions, function (tariff) {
            return tariff.name === tariffName;
        });
        var fixCost = _.sum(this.getFixCostPerPower(tariff, contractedPower));
        if (consumptionType === "power") {
            var variableCost = consumption * tariff.variableCostAverage;
            this.calculatedConsumption = _.ceil(variableCost + fixCost);
            totalConsumptionCalculated = consumption;
        }
        else {
            var variableCost = consumption - Math.min(fixCost, consumption * 0, 2);
            this.calculatedConsumption = _.ceil(variableCost / (tariff.variableCostAverage / 1000) / 1000);
            totalConsumptionCalculated = this.calculatedConsumption;
        }
        return totalConsumptionCalculated;
    };
    Form.prototype.initOffer = function () {
        this.offer.domainId = config_1.config.context.domainId;
        this.offer.powerPlantData = {
            supplyPoint: {
                tariffName: config_1.config.context.defaultTariff,
                contractedPower: config_1.config.context.defaultContractedPower,
                voltage: config_1.config.context.defaultVoltage,
                phases: config_1.config.context.defaultPhases,
            },
        };
        this.offer.legalRepresentation = this.legalRepresentation;
        this.setChannel(config_1.config.context.channelCode);
        this.setWizard(config_1.config.context.wizardCode);
        this.setIndividualProfile(config_1.config.context.defaultProfileRef);
        this.setSolution(config_1.config.context.solutionCode);
    };
    Form.prototype.setIndividualProfile = function (ref) {
        var _this = this;
        var params = {
            where: { domainId: config_1.config.context.domainId, code: ref },
            fields: ["id"],
        };
        this.getAjaxToken(config_1.config.backendHost + "/ConsumptionProfiles/findOne", params, function (data) {
            if (data) {
                _this.individualProfile = data.id;
            }
            else {
                console.error("Error on get Profile");
            }
        });
    };
    Form.prototype.setChannel = function (code) {
        var _this = this;
        var params = {
            where: { domainId: config_1.config.context.domainId },
            fields: ["id"],
        };
        this.getAjaxToken(config_1.config.backendHost + "/Channels/findByCode/" + code, params, function (data) {
            if (data) {
                _this.offer.channelId = data.id;
            }
            else {
                console.error("Error on get Channel");
            }
        });
    };
    Form.prototype.setWizard = function (code) {
        var _this = this;
        var params = {
            where: { domainId: config_1.config.context.domainId },
            fields: ["id"],
        };
        this.getAjaxToken(config_1.config.backendHost + "/Wizards/findByCode/" + code, params, function (data) {
            if (data) {
                _this.offer.wizardId = data.id;
            }
            else {
                console.error("Error on get Wizard");
            }
        });
    };
    Form.prototype.setSolution = function (code) {
        var _this = this;
        var params = {
            where: { domainId: config_1.config.context.domainId },
            fields: ["id", "solutionEngineConfig"],
        };
        this.getAjaxToken(config_1.config.backendHost + "/Solutions/findByCode/" + code, params, function (data) {
            if (data) {
                _this.offer.solutionId = data.id;
                if (_.get(data, "solutionEngineConfig.modules.defaultProduct")) {
                    _this.moduleId = _.get(data, "solutionEngineConfig.modules.defaultProduct");
                }
            }
            else {
                console.error("Error on get Solution");
            }
        });
    };
    Form.prototype.initAutocomplete = function () {
        var _this = this;
        // Create the search box and link it to the UI element.
        var input = document.getElementById("pac-input");
        var options = {
            componentRestrictions: { country: config_1.config.context.regionAutocomplete },
        };
        var autocomplete = new google.maps.places.Autocomplete(input, options);
        // Listen for the event fired when the user selects a prediction and retrieve
        // more details for that place.
        autocomplete.bindTo("bounds", this.mapa.map);
        autocomplete.addListener("place_changed", function () {
            var places = autocomplete.getPlace();
            if (!places.geometry) {
                return;
            }
            // For each place, get the icon, name and location.
            var bounds = new google.maps.LatLngBounds();
            // If the place has a geometry, then present it on a map.
            if (places.geometry.viewport) {
                _this.mapa.map.setCenter(places.geometry.location);
                _this.mapa.map.setZoom(_this.mapa.map.maxZoom - 1);
            }
            else {
                _this.mapa.map.setCenter(places.geometry.location);
                _this.mapa.map.setZoom(_this.mapa.map.maxZoom - 1);
            }
            _this.offer.powerPlantData.supplyPoint.totalConsumption =
                ($("#consumeInput").val() * 12) / 0.289849667;
            _this.mapa.offer = _this.offer;
            if (places.address_components) {
                var data = _this.mapa.calculateTiltAzimuth("tilt", 20);
                _this.mapa.calculateRecommend();
                _this.setAddress(places);
                _this.mapa.searchInMap(places);
                if (_this.validAddress) {
                    $("#step1 .no-valid-address").css("display", "none");
                    _this.goToStep(2);
                    _this.mapa.searchInMap(places);
                }
                else {
                    $("#step1 .no-valid-address").css("display", "block");
                }
            }
        });
    };
    Form.prototype.getUrlVariables = function () {
        var _this = this;
        var href = window.location.href.split("#");
        href[0].replace(/[?&]+([^=&]+)=([^&]*)/gi, function (a, name, value) {
            _this.urlParams[name] = value;
            return "";
        });
        this.search = window.location.search;
        if (this.urlParams["backendHost"]) {
            this.endPoint = this.urlParams["backendHost"];
        }
        if (this.urlParams["key"]) {
            config_1.config.apiKey = this.urlParams["key"];
        }
        if (this.urlParams["ecid"] && config_1.config.redirect) {
            if (config_1.config.thankYouPages[this.urlParams["ecid"]]) {
                this.urlParams.urlOk = config_1.config.thankYouPages[this.urlParams["ecid"]];
            }
            else {
                this.urlParams.urlOk = this.defaultParams.urlOk;
            }
        }
        else {
            if (!this.urlParams["urlOk"] && config_1.config.redirect) {
                this.urlParams.urlOk = this.defaultParams.urlOk;
            }
        }
        $("#backButton").attr("href", this.urlParams.urlBack || this.defaultParams.urlBack);
    };
    Form.prototype.joinDefaultVariables = function (defaultVariables) {
        this.mergeObjects(this.defaultParams, defaultVariables);
    };
    Form.prototype.mergeObjects = function (object1, object2) {
        for (var attrname in object2) {
            object1[attrname] = object2[attrname];
        }
        return object1;
    };
    Form.prototype.goToStep = function (step) {
        this.curStep = step;
        var steps = document.getElementsByClassName("step");
        for (var i = 0; i < steps.length; i++) {
            steps[i].style.display = "none";
        }
        history.pushState(null, null, "#step" + step);
        var step1 = document.getElementById("step" + step);
        step1.style.display = "block";
        if (step == 1) {
            $("#header").css("display", "block");
        }
        if (step == 2) {
            this.mapa.resizeMap();
            $("#header").css("display", "none");
        }
        if (step == 3) {
            this.isIFrame
                ? $("#header").css("display", "none")
                : $("#header").css("display", "block");
        }
    };
    Form.prototype.findCodeAddress = function (array, code) {
        var data = _.find(array, function (item) {
            return (_.findIndex(item.types, function (type) {
                return type === code;
            }) !== -1);
        });
        return data ? data.long_name : null;
    };
    Form.prototype.setAddress = function (places) {
        var street = this.findCodeAddress(places.address_components, "route") ||
            this.findCodeAddress(places.address_components, "town_square");
        var addressNumber = this.findCodeAddress(places.address_components, "street_number");
        var province = this.findCodeAddress(places.address_components, "administrative_area_level_2");
        var town = this.findCodeAddress(places.address_components, "locality");
        var postalCode = this.findCodeAddress(places.address_components, "postal_code");
        var country = this.findCodeAddress(places.address_components, "country");
        if (street && postalCode && town) {
            this.validAddress = true;
            this.offer.address = street;
            this.offer.addressNumber = addressNumber;
            if (postalCode)
                this.setProvince(province, postalCode);
            else
                this.offer.province = province;
            this.offer.town = town;
            this.offer.postalCode = postalCode;
            var address = "";
            if (street)
                address += street + " ";
            if (addressNumber)
                address += addressNumber + ", ";
            if (town)
                address += town + ", ";
            if (postalCode)
                address += postalCode + ", ";
            if (country)
                address += country;
            $("#step2 .map-address .map-address-description input").val(address);
            $("#step3 .address span").text(address);
        }
        else {
            this.validAddress = false;
        }
    };
    Form.prototype.setProvince = function (province, postalCode) {
        var _this = this;
        var postalCodeParsed = postalCode.substring(0, 2);
        var params = {
            where: { id: postalCodeParsed },
            fields: ["name"],
        };
        this.getAjax(config_1.config.backendHost + "/Provinces", params, function (data) {
            if (data) {
                _this.offer.province = data[0].name;
            }
            else {
                _this.offer.province = province;
                console.error("Error on get Province");
            }
        });
    };
    Form.prototype.validateStep3Form = function () {
        var that = this;
        $("#step3Form").validate({
            rules: {
                Name: {
                    required: true,
                },
                // LastName: {
                //   required: true,
                // },
                Email: {
                    required: true,
                    pattern: /^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i,
                },
                Profiles: {
                    required: true,
                },
                documentType: {
                    required: true,
                },
                documentNumber: {
                    required: false,
                    nifES: {
                        depends: function () {
                            return $("#documentType").val() === "nif";
                        },
                    },
                    dniES: {
                        depends: function () {
                            return $("#documentType").val() === "dni";
                        },
                    },
                    nieES: {
                        depends: function () {
                            return $("#documentType").val() === "nie";
                        },
                    },
                },
                PhoneNumber: {
                    required: true,
                    minlength: 9,
                    phoneNumberEs: true,
                },
                // CUPS: {
                //   required: false,
                //   pattern: /^[A-Za-z]{2}[0-9]{16}[A-Za-z]{2}(?:[0-1]{1}[A-Za-z]{1})?$/,
                // },
                Terms: {
                    required: true,
                },
                Invoice: {
                    required: false,
                },
                ContactName: {
                    required: true,
                },
                ContactLastName: {
                    required: true,
                },
                ContactDocumentType: {
                    required: true,
                },
                ContactDocumentNumber: {
                    required: true,
                    nifES: {
                        depends: function () {
                            return $("#contactDocumentType").val() === "nif";
                        },
                    },
                    dniES: {
                        depends: function () {
                            return $("#documentType").val() === "dni";
                        },
                    },
                    nieES: {
                        depends: function () {
                            return $("#contactDocumentType").val() === "nie";
                        },
                    },
                },
            },
            errorPlacement: function (error, element) {
                error.insertAfter(element);
            },
            submitHandler: function (form) {
                var $form = $(form);
                that.offer.name =
                    that.legalRepresentation == "INDIVIDUAL"
                        ? $form.find("input[name=ContactName]").val()
                        : $form.find("input[name=Name]").val();
                that.offer.lastName =
                    that.legalRepresentation == "INDIVIDUAL"
                        ? $form.find("input[name=ContactLastName]").val()
                        : $form.find("input[name=LastName]").val();
                that.offer.email =
                    $form.find("input[name=Email]").val() || that.offer.email;
                that.offer.phoneNumber =
                    $form.find("input[name=PhoneNumber]").val().replace(/\s+/g, "") ||
                        that.offer.phoneNumber;
                that.offer.documentNumber = $form
                    .find("input[name=documentNumber]")
                    .val();
                if (that.urlParams["ecid"]) {
                    that.offer.tags = [];
                    if (config_1.config.campaignCode[that.urlParams["ecid"]]) {
                        that.offer.tags.push("C: " + config_1.config.campaignCode[that.urlParams["ecid"]]);
                    }
                    if (config_1.config.campaignPlaform[that.urlParams["ecid"]]) {
                        that.offer.tags.push("P: " + config_1.config.campaignPlaform[that.urlParams["ecid"]]);
                    }
                }
                that.offer.documentType =
                    $form.find("select[name=documentType]").val() || "nif";
                that.offer.powerPlantData.profileId =
                    that.legalRepresentation == "INDIVIDUAL"
                        ? that.individualProfile
                        : $form.find("select[name=Profiles]").val();
                that.offer.powerPlantData.annualPowerPriceIncrease =
                    config_1.config.context.defaultAnnualPowerPriceIncrease;
                that.offer.powerPlantData.annualPerformanceLoss =
                    config_1.config.context.defaultAnnualPerformanceLoss;
                that.offer.powerPlantData.annualPowerConsumptionIncrease =
                    config_1.config.context.defaultAnnualPowerConsumptionIncrease;
                that.offer.notes = that.setNotes($form);
                that.offer.ecommerceData = {
                    financing: {
                        financingType: config_1.config.context.financingType,
                    },
                };
                that.offer.legalRepresentation = that.legalRepresentation;
                that.offer.contactName =
                    $form.find("input[name=ContactName]").val() || that.offer.contactName;
                that.offer.contactLastName =
                    $form.find("input[name=ContactLastName]").val() ||
                        that.offer.contactLastName;
                that.offer.estimatedSavingsId = that.mapa.estimatedSavingsId;
                that.offer.powerPlantData.supplyPoint.tariffName =
                    $form.find("select[name=Tariff]").val() ||
                        that.offer.powerPlantData.supplyPoint.tariffName;
                that.offer.powerPlantData.supplyPoint.contractedPower =
                    $form.find("input[name=ContractedPower]").val().replace(",", ".") ||
                        that.offer.powerPlantData.supplyPoint.contractedPower;
                that.offer.powerPlantData.supplyPoint.phases = that.mapa.getPhase();
                delete that.offer.slopedRoof;
                that.offer.countryCode = config_1.config.context.defaultCountryCode;
                that.offer.processType = "sales";
                that.submitForm(that.offer);
            },
        });
    };
    Form.prototype.setNotes = function ($form) {
        var notes = $form.find("textarea[name=Notes]").val();
        if ($("#menu-batteries li.current").attr("value") == 1) {
            notes += "\nCon baterías";
        }
        return notes;
    };
    /**
     * Get cups
     * @param estimatedTypeCupsCode cups code
     */
    Form.prototype.getCups = function () {
        var that = this;
        this.getAjaxSearchToken(config_1.config.backendHost + "/ConsumptionProfiles/search", ["name"], {
            bool: {
                must: [
                    { term: { type: "ORGANIZATION" } },
                    { term: { domainId: config_1.config.context.domainId } },
                    { term: { statusId: "PUBLISHED" } },
                ],
                should: [],
            },
        }, 0, { order: { order: "asc" } }, function (data) {
            if (data && data.hits && data.hits.hits) {
                var hits = data.hits.hits;
                var elm = document.getElementById("profiles"), df = document.createDocumentFragment();
                for (var _i = 0, hits_1 = hits; _i < hits_1.length; _i++) {
                    var res = hits_1[_i];
                    var option = document.createElement("option");
                    option.value = res._id;
                    option.appendChild(document.createTextNode(res._source.name));
                    df.appendChild(option);
                }
                elm.appendChild(df);
            }
            else {
                console.error("Error on get ConsumptionProfiles");
            }
        });
    };
    /**
     * Get all tariffs
     */
    Form.prototype.getTariffs = function (forecastPlanCode) {
        var _this = this;
        var params = {
            where: { code: forecastPlanCode },
            fields: ["id"],
        };
        this.getAjax(config_1.config.backendHost + "/ForecastPlans", params, function (data) {
            if (data) {
                var forecastPlanId = data[0].id;
                _this.getTariffOptions(forecastPlanId);
            }
            else {
                console.error("Error on get ForecastPlans");
            }
        });
    };
    Form.prototype.getTariffOptions = function (forecastPlanId) {
        var _this = this;
        var params_2 = {
            order: "name ASC",
            where: {
                and: [
                    { forecastPlanId: forecastPlanId },
                    {
                        name: {
                            inq: this.validTariffs,
                        },
                    },
                    { statusId: "PUBLISHED" },
                ],
            },
        };
        this.getAjax(config_1.config.backendHost + "/ForecastPlanTariffs", params_2, function (data) {
            if (data && data.length > 0) {
                _this.tariffOptions = data;
                var elm = document.getElementById("tariff"), df = document.createDocumentFragment();
                for (var _i = 0, _a = _this.tariffOptions; _i < _a.length; _i++) {
                    var tariffOption = _a[_i];
                    var option = document.createElement("option");
                    option.value = tariffOption.name;
                    option.appendChild(document.createTextNode(tariffOption.name));
                    df.appendChild(option);
                }
                elm.appendChild(df);
            }
            else {
                console.error("Error on get ForescastPlanTariffs");
            }
        });
    };
    /**
     * Calculate and returns the fixCost by its contracted power
     * @param tariff
     * @param contractedPower
     */
    Form.prototype.getFixCostPerPower = function (tariff, contractedPower) {
        var fixCostPerPower = _.fill(Array(6), 0);
        for (var i = 0; i < tariff.fixCost.length; i++) {
            fixCostPerPower[i] = tariff.fixCost[i] * contractedPower;
        }
        return fixCostPerPower;
    };
    /**
     * Send a HTTP GET request
     * @param {*} url
     * @param {*} success
     */
    Form.prototype.getAjax = function (url, params, success) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url + "?filter=" + JSON.stringify(params), false);
        xhr.setRequestHeader("X-Access-Token", config_1.config.apiKey);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    success(JSON.parse(xhr.responseText));
                }
                else {
                    success();
                }
            }
        };
        xhr.send();
    };
    /**
     * Send a HTTP GET request
     * @param {*} url
     * @param {*} success
     */
    Form.prototype.getAjaxNoParams = function (url, success) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url, false);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    success(JSON.parse(xhr.responseText));
                }
                else {
                    success();
                }
            }
        };
        xhr.send();
    };
    /**
     * Send a HTTP GET request
     * @param {*} url
     * @param {*} success
     */
    Form.prototype.getAjaxToken = function (url, params, success) {
        var xhr = new XMLHttpRequest();
        xhr.open("GET", url +
            "?filter=" +
            JSON.stringify(params) +
            "&access_token=" +
            config_1.config.apiKey, false);
        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    success(JSON.parse(xhr.responseText));
                }
                else {
                    success();
                }
            }
        };
        xhr.send();
    };
    /**
     * Send a HTTP GET request to elastic search with access token
     * @param {string} url URL to query
     * @param {string[]} fields Fields that should be taken
     * @param {object} filter Filter to restrict the items to get
     * @param {number} offset Offset for pagination
     * @param {object} sort Sort criteria
     * @param {Function} success Callback on response
     */
    Form.prototype.getAjaxSearchToken = function (url, fields, filter, offset, sort, callback) {
        var params = {
            fields: fields,
            filter: filter,
            offset: offset,
            sort: sort,
        };
        var headers = {};
        if (config_1.config.apiKey) {
            headers = {
                "X-Access-Token": config_1.config.apiKey,
            };
        }
        $.ajax({
            url: url,
            type: "POST",
            dataType: "json",
            headers: headers,
            data: JSON.stringify(params),
            contentType: "application/json",
            success: function (response) {
                callback(response);
            },
            error: function (response) {
                callback();
            },
        });
    };
    Form.prototype.submitForm = function (params) {
        this.setOffer(params, "POST");
    };
    Form.prototype.setOffer = function (params, method) {
        var url = this.endPoint;
        var that = this;
        if (method === "PUT") {
            url += "/" + this.offer.id + "/" + this.offer.token;
        }
        $("button[type=submit], input[type=submit]").prop("disabled", true);
        // Hacemos la llamada AJAX
        if (that.submitted === false) {
            that.submitted = true;
            $("#step3Form").hide();
            $(".send-message .sending").show();
            var headers = {};
            if (config_1.config.apiKey) {
                headers = {
                    "X-Access-Token": config_1.config.apiKey,
                };
            }
            $.ajax({
                url: url,
                type: method,
                dataType: "json",
                headers: headers,
                data: JSON.stringify(params),
                contentType: "application/json",
                success: function (response) {
                    // Guardamos la oferta y vamos al siguiente paso
                    that.offer = response;
                    $(".send-message .sending").hide();
                    if (config_1.config.redirect) {
                        if (that.isIFrame)
                            window.top.location.replace(that.urlParams.urlOk);
                        else
                            window.location.replace(that.urlParams.urlOk);
                    }
                    $("#clientName").text(params.name);
                },
                error: function (response) {
                    //window.location.href = that.urlParams.urlKo;
                    $(".send-message .sending").hide();
                    $("#step3Form").show();
                    $("#formError").modal("show");
                    $("#clientName").text(params.name);
                    that.submitted = false;
                },
            });
        }
    };
    Form.prototype.validateDNI = function (documentNumber) {
        if (!documentNumber.match("((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)"))
            return false;
        if (/^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKE]{1}$/i.test(documentNumber)) {
            if ("TRWAGMYFPDXBNJZSQVHLCKE".charAt(documentNumber.substring(8, 0) % 23) === documentNumber.charAt(8))
                return true;
            else
                return false;
        }
    };
    Form.prototype.setEvents = function () {
        var _this = this;
        var that = this;
        $("#consumeInput").on("change", function () {
            _this.offer.powerPlantData.supplyPoint.totalConsumption =
                ($("#consumeInput").val() * 12) / 0.289849667;
            _this.mapa.calculateRecommend();
        });
        $("#consumeKwInput").on("change", function () {
            _this.offer.powerPlantData.supplyPoint.totalConsumption =
                _this.calculateConsumption("power", $("#consumeKwInput").val() * 12, _this.offer.powerPlantData.supplyPoint.tariffName, _this.offer.powerPlantData.supplyPoint.contractedPower);
            _this.mapa.calculateRecommend();
        });
        $("#togBtn").on("change", function () {
            if ($(this).is(":checked")) {
                $(this).attr("value", "true");
                $(".option1").removeClass("checked");
                $(".option2").addClass("checked");
                $(".option1").css("color", "#6b6b6b");
                $(".option2").css("color", "white");
                $("#step3 .middle-colum").css("min-height", "1055px");
                that.legalRepresentation = "ORGANIZATION";
                $(".organization").css("display", "block");
                $(".individual").css("display", "none");
                $("#consumeInput").attr({ max: config_1.config.context.organizationMaxConsume });
                $("#consumeInput").attr({
                    value: config_1.config.context.organizationDefaultConsume,
                });
                $("#consumeKwInput").attr({
                    max: config_1.config.context.organizationMaxWpConsume,
                });
                $("#consumeKwInput").attr({
                    value: config_1.config.context.organizationDefaultWpConsume,
                });
                $("#consumeOutput").text(config_1.config.context.organizationDefaultConsume);
                $("#consumeKwpOutput").text(config_1.config.context.organizationDefaultWpConsume);
                $("#step3 .tariff").css("display", "block");
                $("#step3 .power").css("display", "block");
                $("#step3 .bussines-name").css("display", "block");
                $("#step3 .profiles-name").css("display", "block");
                that.offer.powerPlantData.supplyPoint.tariffName =
                    config_1.config.context.organizationTariff;
                that.offer.powerPlantData.supplyPoint.contractedPower =
                    config_1.config.context.organizationContractedPower;
            }
            else {
                $(this).attr("value", "false");
                $(".option2").removeClass("checked");
                $(".option1").addClass("checked");
                $(".option1").css("color", "white");
                $(".option2").css("color", "#6b6b6b");
                $("#step3 .middle-colum").css("min-height", "670px");
                that.legalRepresentation = "INDIVIDUAL";
                $(".organization").css("display", "none");
                $(".individual").css("display", "block");
                $("#consumeInput").attr({ max: config_1.config.context.individualMaxConsume });
                $("#consumeInput").attr({
                    value: config_1.config.context.individualDefaultConsume,
                });
                $("#consumeKwInput").attr({
                    max: config_1.config.context.individualMaxWpConsume,
                });
                $("#consumeKwInput").attr({
                    value: config_1.config.context.individualDefaultWpConsume,
                });
                $("#consumeOutput").text(config_1.config.context.individualDefaultConsume);
                $("#consumeKwpOutput").text(config_1.config.context.individualDefaultWpConsume);
                $("#step3 .tariff").css("display", "none");
                $("#step3 .power").css("display", "none");
                $("#step3 .bussines-name").css("display", "none");
                $("#step3 .profiles-name").css("display", "none");
                that.offer.powerPlantData.supplyPoint.tariffName =
                    config_1.config.context.individualTariff;
                that.offer.powerPlantData.supplyPoint.contractedPower =
                    config_1.config.context.individualContractedPower;
            }
            that.offer.legalRepresentation = that.legalRepresentation;
        });
        $("#consumptionSlider").on("change", function () {
            if ($(this).is(":checked")) {
                $("#consumeKwInput").trigger("change");
                $(".euros-consumption").removeClass("checked");
                $(".kw-consumption").addClass("checked");
                $(".euros-consumption").css("color", "#60aabe");
                $(".kw-consumption").css("color", "white");
                $(".euros-slider").css("display", "none");
                $(".kwp-slider").css("display", "block");
                $(".max").css("font-size", "15px");
                $(".min").css("font-size", "15px");
                $(".consumeForm .circle").css("width", "100px");
                $(".consumeForm .circle").css("margin", "7px auto 0px");
            }
            else {
                $("#consumeInput").trigger("change");
                $(".euros-consumption").addClass("checked");
                $(".kw-consumption").removeClass("checked");
                $(".euros-consumption").css("color", "white");
                $(".kw-consumption").css("color", "#60aabe");
                $(".kwp-slider").css("display", "none");
                $(".euros-slider").css("display", "block");
                $(".max").css("font-size", "18px");
                $(".min").css("font-size", "18px");
                $(".consumeForm .circle").css("width", "77px");
                $(".consumeForm .circle").css("margin", "2px auto 0px");
            }
        });
        $("#documentType").val("nif");
        window.addEventListener("popstate", function (e) {
            if (that.curStep == 2) {
                $("body").removeClass();
                that.goToStep(1);
                that.mapa.clearListeners();
                that.mapa.resetMap();
            }
            if (that.curStep == 3) {
                that.goToStep(2);
            }
        });
        if (this.urlParams.referral && this.urlParams.referral === "true") {
            this.isReferralToggle = true;
            $("#ref-contact-step1").hide();
            $("#logo-header").attr("href", "");
            $("#row-referralEmployee, #row-referralEmail").show();
        }
        else {
            this.isReferralToggle = false;
        }
        $("#submitStep1").on("click", function () {
            $("#step1Form").submit();
        });
        $("#submitStep2").on("click", function () {
            that.submitStep2();
        });
        $("#step1 .send-button").on("click", function () {
            if (that.urlParams && that.urlParams.ecid) {
                window.top.location.replace(config_1.config.context.urlContact + "&ecid=" + that.urlParams.ecid);
            }
            else {
                window.top.location.replace(config_1.config.context.urlContact);
            }
        });
        $("#gotostep3").on("click", function () {
            that.submitStep2();
        });
        $(document).on("click", "#submitStep3", function () {
            $("#step3Form").submit();
        });
        $('[data-toggle="tooltip"]').tooltip();
        $("#documentNumber").on("focus", function () {
            $(".side-alert").addClass("animate-opacity");
        });
        $("#direccion").on("focus", function () {
            $(".street-message").show();
        });
        $("#direccion").on("blur", function () {
            $(".street-message").hide();
        });
        $("#documentNumber").on("blur", function () {
            $(".side-alert").removeClass("animate-opacity");
        });
        $("#step1 .no-valid-address").css("display", "none");
        $("#step2 .map-address-modify button").on("click", function () {
            $("body").removeClass();
            $("#pac-input").val("");
            $("#step1 .no-valid-address").css("display", "none");
            that.goToStep(1);
            that.mapa.clearListeners();
            that.mapa.resetMap();
        });
        $("#step1 .content .start-button-div button").on("click", function () {
            var hasAddress = false;
            var inputValue = $("input#pac-input").val();
            if (inputValue) {
                hasAddress = true;
                if (!that.validAddress) {
                    $("#step1 .error-message").css("display", "block");
                    $("#step1 .error-address").css("display", "none");
                }
            }
            else {
                $("#step1 .error-address").css("display", "block");
                $("#step1 .error-message").css("display", "none");
                hasAddress = false;
            }
            if (hasAddress && that.validAddress) {
                $(".minConsumeKw").text("125kWh");
                $(".maxConsumeKw").text("999kWh");
                $("#consumeKwpOutput").text("125");
                $(".minConsume").text("40€");
                $("#consumeOutput").text("50");
                $(".maxConsume").text("285€");
                that.goToStep(2);
            }
        });
        $("#step3 .map-address-modify button").on("click", function () {
            that.goToStep(2);
        });
        $(".send-message button").on("click", function () {
            if (config_1.config.redirect) {
                location.href = _this.defaultParams.urlOk + _this.search;
            }
        });
        $(window).on("resize", function () {
            if (that.curStep == 2) {
                that.mapa.resizeMap();
            }
        });
        $.validator.addMethod("phoneNumberEs", function (value, element) {
            "use strict";
            value = value.replace(/\s+/g, "");
            return this.optional(element) || value.match(/^\d{9}$/);
        });
        $.validator.addMethod("nieES", function (value, element) {
            "use strict";
            value = value.toUpperCase();
            // Basic format test
            if (!value.match("((^[A-Z]{1}[0-9]{7}[A-Z0-9]{1}$|^[T]{1}[A-Z0-9]{8}$)|^[0-9]{8}[A-Z]{1}$)")) {
                return false;
            }
            // Test NIE
            //T
            if (/^[T]{1}/.test(value)) {
                return value[8] === /^[T]{1}[A-Z0-9]{8}$/.test(value);
            }
            //XYZ
            if (/^[XYZ]{1}/.test(value)) {
                return (value[8] ===
                    "TRWAGMYFPDXBNJZSQVHLCKE".charAt(value
                        .replace("X", "0")
                        .replace("Y", "1")
                        .replace("Z", "2")
                        .substring(0, 8) % 23));
            }
            return false;
        });
        $.validator.addMethod("passportES", function (value, element) {
            "use strict";
            value = value.toUpperCase();
            return false;
        });
        $.validator.addMethod("documentNumber", function (value, element) {
            "use strict";
            value = value.toUpperCase();
            if (value == "" ||
                /^[ABCDEFGHJNPQRSUVW][0-9]{8}$/.test(value) ||
                /^[ABCDEFGHJNPQRSUVW][0-9]{7}[A-J]$/.test(value) ||
                /^[ABCDEFGHJNPQRSUVW][0-9]{7}[0-9A-J]$/.test(value) ||
                that.validateDNI(value)) {
                return true;
            }
            else
                return false;
        }, function () {
            if (that.getLanguage() === "es") {
                return "El formato del documento no es correcto";
            }
            if (that.getLanguage() === "cat") {
                return "El format del document no és correcte";
            }
        });
        $.validator.addMethod("dniES", function (value, element) {
            "use strict";
            // Basic format test
            return that.validateDNI(value.toUpperCase());
        });
    };
    Form.prototype.getLanguage = function () {
        var lang = i18next.default.options.fallbackLng[0];
        if (i18next.default.language != undefined) {
            lang = i18next.default.language;
        }
        return lang;
    };
    Form.prototype.submitStep2 = function () {
        $("#step3 .m2_in_map span").html(this.numberWithPoints(this.mapa.areaM2.reduce(function (a, b) {
            return a + b;
        }, 0)) + "m<sup>2</sup>");
        if (this.mapa.offer) {
            this.offer = this.mapa.offer;
            this.offer.powerPlantData.map = {
                surfaceCoordinates: this.mapa.surfaceCoordinates,
                coordinates: this.mapa.coordinates,
                elevation: this.mapa.elevation,
                layout: {
                    map: {
                        zoom: 18,
                        geopoint: this.mapa.coordinates,
                        surfaceCoordinates: this.mapa.surfaceCoordinates,
                        elevation: this.mapa.elevation,
                    },
                    modules: this.mapa.modulesPolygonsLayout,
                    moduleArea: this.mapa.moduleArea,
                    totalModules: this.mapa.totalModules,
                    totalPower: this.mapa.totalPower,
                    totalSurface: this.mapa.totalSurface,
                    batteriesPower: 0,
                    batteryLines: [],
                    defaultModuleId: this.mapa.moduleId,
                    defaultStructureId: this.mapa.inclinationStructure,
                },
            };
            for (var _i = 0, _a = this.offer.powerPlantData.map.layout.moduleArea; _i < _a.length; _i++) {
                var area = _a[_i];
                area.structureId = this.mapa.inclinationStructure;
            }
        }
        $(".left-colum").css("left", "400px");
        $(".right-colum").css("left", "-400px");
        this.goToStep(3);
        $(".left-colum").animate({ left: "0px" }, "slow");
        $(".right-colum").animate({ left: "0px" }, "slow");
        this.mapa.getStaticMap(this.mapa.surfaceCoordinates);
    };
    Form.prototype.numberWithPoints = function (x) {
        if (x != undefined) {
            x = Math.floor(x);
            x = x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }
        else {
            x = 0;
        }
        return x;
    };
    return Form;
}());
exports.Form = Form;
