"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.messages_es = void 0;
exports.messages_es = {
    Address: "La dirección es obligatoria",
    Province: "La provincia es obligatoria",
    PostalCode: {
        required: "El código postal es obligatorio",
        pattern: "El código postal no tiene el formato correcto",
    },
    Profiles: {
        required: "El tipo de empresa es obligatorio",
    },
    Number: "El número es obligatorio",
    City: "La localidad es obligatoria",
    Tariff: "La tarifa es obligatoria",
    ContractedPower: {
        required: "La potencia contratada es obligatoria",
        pattern: "La potencia no tiene el formato correcto",
    },
    Name: "El nombre es obligatorio",
    LastName: "El apellido es obligatorio",
    Email: {
        required: "El email es obligatorio",
        pattern: "Por favor, escribe una dirección de correo válida. Por ejemplo perez@dominio.com",
        email: "Por favor, escribe una dirección de correo válida. Por ejemplo perez@dominio.com",
    },
    referralEmployee: {
        required: "El nombre del gestor es obligatorio",
    },
    referralEmail: {
        required: "El email del gestor es obligatorio",
        pattern: "Los dominios admitidos son endesa.com, enel.com, endesa.es y enel.es",
        email: "Por favor, escribe una dirección de correo válida.",
    },
    Terms: "Debes aceptar las condiciones",
    documentNumber: {
        required: "El documento es obligatorio",
        pattern: "El formato del documento no es correcto",
        nifES: "Por favor, introduce un NIF válido",
        dniES: "Por favor, introduce un DNI válido",
        nieES: "Por favor, introduce un NIE válido",
    },
    PhoneNumber: {
        required: "El teléfono es obligatorio",
        minlength: "El teléfono debe tener 9 números",
        number: "El teléfono debe componerse de números",
        phoneNumberEs: "El formato del telefono no es correcto",
    },
    CUPS: {
        required: "El CUPS es obligatorio",
        pattern: "El formato del CUPS no es correcto",
    },
    ContactName: {
        required: "El nombre es obligatorio",
    },
    ContactLastName: {
        required: "El apellido es obligatorio",
    },
    ContactDocumentNumber: {
        required: "El documento es obligatorio",
        pattern: "El formato del documento no es correcto",
        dniES: "Por favor, introduce un DNI válido",
        nieES: "Por favor, introduce un NIE válido",
    },
    TotalConsumption: {
        required: "El consumo es obligatorio",
        pattern: "El formato del consumo debe ser numérico",
    },
};
